// Music artist data
// var griddy_data = {
//   header_data: [
//     { header: 'Name', name: 'name' },
//     { header: 'Artist', name: 'artist' },
//     { header: 'Type', name: 'type' },
//     { header: 'Release', name: 'release' },
//     { header: 'Genre', name: 'genre' }
//   ],
//   row_data: [
//     { id: 549731, name: 'Beautiful Lies', artist: 'Birdy', release: '2016.03.26', type: 'Deluxe', typeCode: '1', genre: 'Pop', genreCode: '1', grade: '2', price: 10000, downloadCount: 1000, listenCount: 5000, },
//     { id: 436461, name: 'X', artist: 'Ed Sheeran', release: '2014.06.24', type: 'Deluxe', typeCode: '1', genre: 'Pop', genreCode: '1', grade: '3', price: 20000, downloadCount: 1000, listenCount: 5000, }
//   ]
// };
//

// Some alternate ways to utilize tui.grid's API
// console.log("getModifiedRows", grid.getModifiedRows());
// grid.request("modifyData");

import Grid from "tui-grid"; /* ES6 */
import "tui-grid/dist/tui-grid.css";
import "tui-date-picker/dist/tui-date-picker.css";
import "tui-time-picker/dist/tui-time-picker.css";
import "tui-grid/dist/tui-grid.css";
import "tui-pagination/dist/tui-pagination.css";

var onmount = require("onmount");

var theme = {
  selection: {
    background: "#4daaf9",
    border: "#004082",
  },
  scrollbar: {
    background: "#f5f5f5",
    thumb: "#797979",
    active: "#313131",
  },
  row: {
    even: {
      background: "#c2defc",
    },
    hover: {
      background: "#c2fbfc",
    },
  },
  cell: {
    normal: {
      background: "#fbfbfb",
      border: "#e0e0e0",
      showVerticalBorder: true,
    },
    header: {
      background: "#eee",
      border: "#ccc",
      showVerticalBorder: true,
    },
    rowHeader: {
      border: "#ccc",
      showVerticalBorder: true,
    },
    editable: {
      background: "#fbfbfb",
    },
    selectedHeader: {
      background: "#d8d8d8",
    },
    focused: {
      border: "#418ed4",
    },
    disabled: {
      text: "#b0b0b0",
    },
  },
};

class CheckboxRenderer {
  constructor(props) {
    const { grid, rowKey } = props;

    const label = document.createElement("label");
    label.className = "checkbox";
    label.setAttribute("for", String(rowKey));
    label.setAttribute("style", "margin-bottom: 30px; margin-left: 30px;");

    const hiddenInput = document.createElement("input");
    hiddenInput.className = "hidden-input";
    hiddenInput.id = String(rowKey);

    const customInput = document.createElement("span");
    customInput.className = "custom-input";

    label.appendChild(hiddenInput);
    label.appendChild(customInput);

    hiddenInput.type = "checkbox";
    hiddenInput.addEventListener("change", () => {
      if (hiddenInput.checked) {
        grid.check(rowKey);
      } else {
        grid.uncheck(rowKey);
      }
    });

    this.el = label;

    this.render(props);
  }

  getElement() {
    return this.el;
  }

  render(props) {
    const hiddenInput = this.el.querySelector(".hidden-input");
    const checked = Boolean(props.value);

    hiddenInput.checked = checked;
  }
}

const updateActiverecordModel = (initParams, row, columnName, value, prevValue, activerecord_attributes, do_error) => {
  fetch("/super_data_entry/super_update", {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-CSRF-Token": document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify({
      activerecord_class: initParams.activerecord_class,
      activerecord_id: row.id,
      activerecord_attributes: activerecord_attributes,
    }),
  }).then(
    (response) => {
      console.log(response);
      if (response.ok) {
        window.notyf.success(
          `🌕 <code>${columnName}</code> changed OK! <br><br>Old: <code style="background-color: #AAAA00; color: #FFF">${prevValue}</code><br>New: <code style="background-color: #00AA00; color: #FFF">${value}</code>`
        );
      } else {
        response.json().then((data) => {
          do_error(data.error.reason);
        });
      }
    },
    (reason) => {
      console.log("reason", reason);
      do_error(reason);
    }
  );
}

const griddy = (griddy_id, griddy_data) => {
  griddy_data.header_data["height"] = 100;
  var cell_rolling_back = false;
  var default_values = griddy_data.default_values;
  var default_columns_sort = griddy_data.default_columns_sort;

  var initParams = {
    activerecord_class: griddy_data.activerecord_class,
    activerecord_ids: griddy_data.activerecord_ids,
  };

  // <meta name="csrf-param" content="authenticity_token" />
  // <meta name="csrf-token" content="/gJjO1vvzZLYeHLF6ENqwffCTL8yErMHpgfGZ+DsQCW6MJE36F8ESXs+2L4FsuJgUfJ3pU1c+kd5B4XJUOn1jg==" />
  var csrf_token = document
    .querySelector('meta[name="csrf-token"]')
    .getAttribute("content");

  const dataSource = {
    headers: { accept: "application/json", "X-CSRF-Token": csrf_token },
    contentType: "application/json",
    api: {
      readData: {
        url: "/super_data_entry/super_read",
        method: "POST",
        initParams: initParams,
      },
      // createData: { url: "/super_data_entry/super_create", method: "POST" },
      // updateData: { url: "/super_data_entry/super_update", method: "PUT" },
      // deleteData: { url: "/super_data_entry/super_delete", method: "DELETE" },
      // // This one is for sending it all at once - deletes/updates/adds
      // modifyData: { url: "/super_data_entry/super_update", method: "PUT" },
    },
  };

  const grid = new Grid({
    el: document.querySelector(`[data-griddy-id="${griddy_id}"]`),
    scrollX: true,
    scrollY: true,
    bodyHeight: "fitToParent",
    columns: griddy_data.column_data,
    columnOptions: {
      resizable: true,
    },
    header: griddy_data.header_data,
    usageStatistics: false,
    data: dataSource,
    rowHeaders: [
      {
        type: "checkbox",
        header: `
          <label for="all-checkbox" class="checkbox">
            <input type="checkbox" id="all-checkbox" class="hidden-input" name="_checked" />
            <span class="custom-input" style="margin-left: 30px; margin-right: 30px;"></span>
          </label>
        `,
        renderer: {
          type: CheckboxRenderer,
        },
      },
    ],
  });

  var do_error = function (row, reason, action) {
    var message = null;

    if (row["id"]) {
      message = `🔥🌑🔥 <br> ${action} <code>${row.id}</code> failed! <br><br>Reason: ${reason}`;
    } else {
      message = `🔥🌑🔥 <br> ${action} failed! <br><br>Reason: ${reason}`;
    }
    window.notyf.error({
      message: message,
      dismissible: true,
    });
    // TODO: add javascript-call to Bugsnag

    // This rolls re-enables the row visually in the tui.grid in our
    // client-side javascript land
    grid.enableRow(row.rowKey);
    resetDisabledColumns();
  };

  var createData = function () {
    grid.getModifiedRows().createdRows.forEach((change) => {
      var activerecord_attributes = null;
      var row = null;
      var value = null;
      var prevValue = null;
      var columnName = null;
      row = change;
      activerecord_attributes = change;

      // Prevent sending request when row is already persisted
      if (row._persisted) {
        return;
      }

      grid.disableRow(row.rowKey);

      fetch("/super_data_entry/super_create", {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-Token": document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: "follow", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({
          activerecord_class: initParams.activerecord_class,
          activerecord_attributes: activerecord_attributes,
        }),
      }).then(
        (response) => {
          if (response.ok) {
            response.json().then((data) => {
              window.notyf.success(`🌕 Record created!`);
              var attributes = data.success.activerecord_attributes;
              attributes["_persisted"] = true;
              grid.setRow(row.rowKey, attributes);
              grid.enableRow(row.rowKey);
              resetDisabledColumns();
            });

            disablePublishRowButton();
          } else {
            response.json().then((data) => {
              do_error(
                activerecord_attributes,
                `Server rejected changes - ${data.error.reason}`,
                "Create"
              );

              enabledPublishRowButton();
            });
          }
        },
        (reason) => {
          console.log("reason", reason);
          do_error(activerecord_attributes, reason, "Create");
        }
      );
    });
  };

  var enabledPublishRowButton = () => {
    document.querySelector(
      `[data-js-griddy-publish-row="${griddy_id}"]`
    ).disabled = "";
  };
  var disablePublishRowButton = () => {
    document.querySelector(
      `[data-js-griddy-publish-row="${griddy_id}"]`
    ).disabled = "disabled";
  };

  var resetDisabledColumns = () => {
    griddy_data.column_data.forEach((column) => {
      if (column.disabled) {
        grid.disableColumn(column.name);
      }
    });
  };

  document
    .querySelector(`[data-js-griddy-add-row="${griddy_id}"]`)
    .addEventListener("click", function (event) {
      grid.appendRow(default_values, { focus: true });
      // Enable the add rows button
      enabledPublishRowButton();
    });

  document
    .querySelector(`[data-js-griddy-publish-row="${griddy_id}"]`)
    .addEventListener("click", function (event) {
      createData();
    });

  document
    .querySelector(`[data-js-griddy-delete-row="${griddy_id}"]`)
    .addEventListener("click", function (_event) {
      if (!window.confirm("Are you sure?")) {
        return;
      }

      grid.getCheckedRowKeys().forEach((rowKey) => {
        grid.disableRow(rowKey);
      });

      grid.getCheckedRowKeys().forEach((rowKey) => {
        var row = grid.getRow(rowKey);

        // Prevent sending request when row is not persisted
        if (!row._persisted) {
          return;
        }
        fetch("/super_data_entry/super_delete", {
          method: "DELETE", // *GET, POST, PUT, DELETE, etc.
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-CSRF-Token": document
              .querySelector('meta[name="csrf-token"]')
              .getAttribute("content"),
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: "follow", // manual, *follow, error
          referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify({
            activerecord_class: initParams.activerecord_class,
            activerecord_id: row.id,
          }),
        }).then(
          (response) => {
            console.log(response);
            if (response.ok) {
              window.notyf.success(`🌕 <code>${row.id}</code> deleted!`);
            } else {
              do_error(row, "Server rejected changes", "Delete");
            }
          },
          (reason) => {
            console.log("reason", reason);
            do_error(row, reason, "Delete");
          }
        );
      });
      grid.removeCheckedRows();
    });

  // afterChange
  // Occurs after one or more cells is changed
  //
  // PROPERTIES
  // origin -  string -           The type of change('paste', 'delete', 'cell')
  // changes -  Array.<object> -  rowKey, column name, previous values and changed values after changing the values
  // instance - Grid -            Current grid instance
  grid.on("afterChange", (grid_event) => {
    var origin = grid_event.origin;
    var stopped = grid_event.stopped;

    if (cell_rolling_back) {
      cell_rolling_back = false;
      return;
    }

    // var changes = [{
    //    columnName: "team_fouls",
    //    prevValue: null,
    //    rowKey: 1,
    //    value: "3"
    // }]
    var changes = grid_event.changes;
    //{
    //  "activerecord_class": "Basketball::BoxScore",
    //  "activerecord_id": "176372",
    //  "activerecord_attributes": {
    //    "id": "176372",
    //    "event_id": "528994",
    //    "minutes": "0",
    //    "seconds": "30",
    //    "active_state": "false",
    //    "coverage_level": "",
    //    "home_score": "77",
    //    "away_score": "100",
    //    "created_at": "2021-03-21 21:50:03 -0400",
    //    "updated_at": "2021-03-26 14:12:23 -0400",
    //    "half": "2",
    //    "attendance": "0",
    //    "referees": "",
    //    "segment": "2",
    //    "game_winning_buzzer_beater_player_id": "",
    //    "player_records_count": "31",
    //    "home_timeouts_left": "",
    //    "away_timeouts_left": ""
    //  }
    //}
    var activerecord_attributes = {};

    var row = null;
    var value = null;
    var prevValue = null;
    var columnName = null;

    changes.forEach((change) => {
      row = grid.getRow(change.rowKey);
      value = change.value;
      prevValue = change.prevValue;
      columnName = change.columnName;
      activerecord_attributes[change.columnName] = change.value;

      // Do not send network request to update value if our row is not
      // persisted yet.
      if (!row._persisted) {
        return;
      }

      var do_error = function (server_message) {
        window.notyf.error({
          message: `🔥🌑🔥 <br> <code>${columnName}</code> update failed! <br><br>Attempted: <code style="background-color: #AA0000; color: #FFF">${value}</code><br>Reset to: <code style="background-color: #AAAA00; color: #FFF;">${prevValue}</code><br>Server Message: ${server_message}`,
          dismissible: true,
        });
        // TODO: add javascript-call to Bugsnag

        // This rolls back each change visually in the tui.grid in our
        // client-side javascript land
        changes.forEach((change) => {
          cell_rolling_back = true;
          row = grid.getRow(change.rowKey);
          grid.setValue(change.rowKey, change.columnName, change.prevValue);
          console.log(
            "id, columnName, prevValue",
            row.id,
            change.columnName,
            change.prevValue
          );
        });
      };

      updateActiverecordModel(initParams, row, columnName, value, prevValue, activerecord_attributes, do_error);
    });
  });

  window.Grid = Grid;

  if (window.grids instanceof Object) {
    window.grids[griddy_id] = grid
  } else {
    window.grids = {[griddy_id]: grid}
  }

  setTimeout(() => {
    Grid.applyTheme("striped", theme);

    // sort columns
    let url_params_key = `sortGriddy[${initParams.activerecord_class}]`;
    let empty_default_columns_sort = Object.keys(default_columns_sort).length === 0;

    if (!empty_default_columns_sort) sortGridColumns(grid, url_params_key, default_columns_sort);
    grid.on("beforeSort", (event) => addSortColumnToUrl(event, url_params_key, default_columns_sort));
    grid.on("afterUnsort", (event) => {
      // default desc column fix
      // when you have multiple default columns, any desc column will get stuck without this
      if (event.columnName === event.sortState.columns[0].columnName) return;

      if (empty_default_columns_sort) {
        removeSortColumnFromUrl(url_params_key);
      } else {
        sortGridColumns(event.instance, url_params_key, default_columns_sort, true);
      }
    });
  }, 1000);
}

const sortGridColumns = (grid, url_params_key, default_columns_sort, ignore_url = false) => {
  let url = new URL(window.location.href);
  let url_params = url.searchParams;
  let url_columns_sort = decodeSortURLSearchParams(url_params, url_params_key);
  let empty_url_columns_sort = Object.keys(url_columns_sort).length === 0;

  let columns_sort = (empty_url_columns_sort || ignore_url) ? default_columns_sort : url_columns_sort;

  for (let [columnName, order] of Object.entries(columns_sort)) {
    let ascending = order === "asc";
    grid.sort(columnName, ascending, true);
  }
}

const addSortColumnToUrl = (grid_sort_event, url_params_key, default_columns_sort) => {
  let url = new URL(window.location.href);
  let url_params = url.searchParams;
  let grid_columns_sort = decodeGridSortColumns(grid_sort_event);

  if (_.isEqual(grid_columns_sort, default_columns_sort)) {
    // delete default columns sort from URL params
    url_params.delete(url_params_key);
  } else {
    // add columns sort to URL params
    let encoded_url_params = encodeSortURLSearchParams(grid_columns_sort);
    url_params.set(url_params_key, encoded_url_params);
  }

  window.history.replaceState({}, '', url.href);
}

const removeSortColumnFromUrl = (url_params_key) => {
  let url = new URL(window.location.href);
  let url_params = url.searchParams;

  url_params.delete(url_params_key);
  window.history.replaceState({}, '', url.href);
}

const decodeGridSortColumns = ({ columnName, ascending, multiple, sortState: { columns } }) => {
  let order = function (ascending) { return ascending ? "asc" : "desc" };
  let columns_sort = { [columnName]: order(ascending) };
  let emptySortState = columns.length === 1 && columns[0].columnName === "sortKey"
  if (!multiple || emptySortState) return columns_sort;

  let existing_columns_sort = {};

  columns.forEach(({ columnName, ascending }) => {
    existing_columns_sort[columnName] = order(ascending);
  });

  return Object.assign(existing_columns_sort, columns_sort);
}

const decodeSortURLSearchParams = (params, key) => {
  let encoded_columns = params.get(key);
  if (encoded_columns === null) return {};

  let decoded_columns = encoded_columns.split(';').map(column_and_order => column_and_order.split(':'));
  return Object.fromEntries(decoded_columns);
}

const encodeSortURLSearchParams = (decoded_columns) => {
  return Object.entries(decoded_columns)
               .map(column_and_order => column_and_order.join(":"))
               .join(';');
}

const ready = (callbackFunction) => {
  if (document.readyState !== "loading") {
    callbackFunction(event);
  } else {
    document.addEventListener("DOMContentLoaded", callbackFunction);
  }
}

const refreshGridLayoutAfterTabChange = () => {
  const observer = new MutationObserver((mutationList, _observer) => {
    if (mutationList.length > 0)
      // fixes https://github.com/nhn/tui.grid/issues/1393
      // reference: https://nhn.github.io/tui.grid/4.21.7/Grid#refreshLayout
      Object.entries(window.grids)?.forEach(id_and_grid => id_and_grid[1].refreshLayout());
  });

  const tab = document.querySelector('[role="tablist"]')

  // TODO: would be better to listen a "tab change" event
  if (tab) observer.observe(tab, { attributes: true, subtree: true })
}

var dateTimeFormatter = function(prop) {
  const formatter = new Intl.DateTimeFormat("en-CA", {
    formatMatcher: "basic",
    timeZone: "America/Toronto",
    timeZoneName: "short",
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
  if (prop.value) {
    let date = new Date(Date.parse(prop.value));
    return formatter.format(date)
  }
  else return prop.value;

}

function convertFormatter(data) {
  var newData = data;
  for (let i = 0; i < data.column_data.length; i++) {
    if (data.column_data[i].formatter === "dateTimeFormatter") {
      newData.column_data[i].formatter = dateTimeFormatter;
    }
  }
  return newData;
}

onmount(".griddy-table", function() {
  let id = this.dataset.griddyId;
  let data = JSON.parse(this.dataset.griddyData);
  data = convertFormatter(data);
  griddy(id, data)
});

ready((event) => {
  window.onmount();
  refreshGridLayoutAfterTabChange()
  console.log("DOM is ready.");
});
